<template>
  <router-view class="router-view" />
</template>

<script>
const { getClientSettings } = require('@/app/routes');

export default {
  name: 'App',
  data() {
    return {
      authed: false,
      email: localStorage.email,
      settings: {
        name: '',
        logo: '',
        colourP: '',
        colourS: '',
        colourT: '',
        text: '',
        hasBookings: false,
        hasBlog: false,
        hasOnDemand: false,
      }
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('userSession');
      localStorage.removeItem('operator');
      localStorage.removeItem('admin');
      this.$router.push('/login');
      this.authed = false;
      this.operator = false;
      this.admin = false;
    },
    setSize() {
      if (window.innerWidth < 600) {
        this.nav = false;
        this.mobileNav = true;
      }
    },
    toggleAdminNav() {
      if (this.showNav) {
        this.showNav = false;
        localStorage.showNav = 'false';
      } else {
        this.showNav = true;
        localStorage.showNav = 'true';
      }
    },
    async getSettings() {
      const settings = await getClientSettings();
      if (!settings) return;
      localStorage.setItem('settings', JSON.stringify(settings));
      this.settings = settings;
      // Apply the settings
      document.documentElement.style.setProperty('--colourP', settings.colourP || '#0074d9');
      document.documentElement.style.setProperty('--colourS', settings.colourS || '#7fdbff');
      document.documentElement.style.setProperty('--colourT', settings.colourT || '#39cccc');
      document.documentElement.style.setProperty('--text', settings.text || '#2c3e50');
    },
  },
  mounted() {
    if (window.location.pathname.split('/')[1] == 'admin') {
      this.admin = true;
      document.getElementsByTagName('body')[0].classList.add('admin');
      if (localStorage.getItem('adminSession')) this.authed = true;
      else this.$router.push('/admin/login');
    } else {
      document.getElementsByTagName('body')[0].classList.add('main');
      if (localStorage.getItem('userSession')) this.authed = true;
    }

    this.setSize();
    window.addEventListener('resize', this.setSize);
    this.getSettings();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
}
</script>

<style lang="scss">
@import "/src/assets/styles/variables.scss";

body.main {
  margin: 0;
  padding: 0;
}

.main {
  #app {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $text;
    letter-spacing: 0.8px;
  }
}

.fa-spinner {
  animation: spin 2s linear infinite;
}

// Loader
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 600px) {
  .router-view, #app {
    left: 0;
    width: 100%;
  }
}
</style>
