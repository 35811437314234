import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // iFrame components
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue')
  },
  {
    path: '/book',
    name: 'book',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "book" */ '../views/BookView.vue')
  },

  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/BlogView.vue')
  },
  {
    path: '/blog/:id',
    name: 'blog-id',
    component: () => import('../views/BlogPageView.vue')
  },
  {
    path: '/on-demand',
    name: 'on-demand',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/OnDemandView.vue')
  },
  {
    path: '/on-demand/:id',
    name: 'on-demand-id',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/OnDemandWatchView.vue')
  },
  // Hosted components
  {
    path: '/reset_user_password/:id',
    name: 'password',
    component: () => import('../views/PasswordView.vue')
  },
  {
    path: '/offers/:site_id/:offer_id',
    name: 'offers',
    component: () => import('../views/OffersView.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import(/* webpackChunkName: "subscribe" */ '../views/CheckoutView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not found',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NoPage.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!sessionStorage.userSession) {
      next({ name: 'login', query: { p: to.name } });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (!sessionStorage.adminSession) {
      next({ name: 'admin login' });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
